@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    position: relative;
    text-align: center;
    width: 100%;
}

.svg-container {
    position: absolute;
    left: 50%;
    top: 25.1%;
    transform: translate(-50%, -50%);
    width: 165px;
}

.back-svg {
    width: 600px;
}

.description-title {
    font-size: 3rem !important;
}

.description-content {
    line-height: 2rem !important;
}

.container svg {
    transform: scale(0.42);
}

@media (max-width: 1300px) {
    .back-svg {
        width: 400px;
    }

    .description-title {
        font-size: 2.5rem !important;
        line-height: 1;
    }

    .description-content {
        font-size: 1.25rem !important;
        line-height: 1.75rem;
    }

    .container svg {
        transform: scale(0.27);
    }

    .svg-container {
        width: 108px;
        top: 26%;
    }
}

@media (max-width: 1023px) {
    .back-svg {
        width: 400px;
    }

    .container svg {
        transform: scale(0.27);
    }

    .container {
        min-width: 100%;
    }

    .svg-container {
        top: 25%;
    }
}

@media (max-width: 950px) {
    .back-svg {
        width: 400px;
    }

    .description-title {
        font-size: 2.5rem !important;
        line-height: 1;
    }

    .description-content {
        font-size: 1.15rem !important;
        line-height: 1.75rem;
    }

    .container svg {
        transform: scale(0.27);
    }
}

@media (max-width: 700px) {
    .back-svg {
        width: 350px;
    }

    .container svg {
        transform: scale(0.235);
    }
    .svg-container {
        width: 95px;
    }
}

@media (max-width: 600px) {
    .back-svg {
        width: 300px;
    }

    .container svg {
        transform: scale(0.2);
    }

    .description-title {
        font-size: 1.5rem !important;
        line-height: 2rem;
    }

    .description-content {
        font-size: 0.75rem !important;
        line-height: 1.25rem !important;
    }

    .svg-container {
        top: 25.5%;
        width: 80px;
    }
}

@media (max-width: 280px) {
    .back-svg {
        width: 250px;
    }

    .container svg {
        transform: scale(0.17);
    }

    .svg-container {
        top: 26.5%;
        width: 68px;
    }
}
